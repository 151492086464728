<template>
  <div class="user">
    <div class="photo">
      <img src="static/img/faces/MM_profile.webp" alt="user avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{ store.mmData && store.mmData.user.first_name }} {{ store.mmData && store.mmData.user.last_name[0] }}.
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li v-if="store.mmData && store.mmData.user.is_superuser">
              <a href="#/pages/createmundial">
                <span class="sidebar-mini-icon">Cm</span>
                <span class="sidebar-normal">Create Mundial User</span>
              </a>
            </li>
            <li v-if="store.mmData && store.mmData.user.is_staff">
              <a href="#/pages/createuser">
                <span class="sidebar-mini-icon">Cu</span>
                <span class="sidebar-normal">Create {{ MUNDIAL_PLATFORM }} User</span>
              </a>
            </li>
            <li v-if="store.mmData && store.mmData.user.is_staff">
              <a href="#/pages/manageusers">
                <span class="sidebar-mini-icon">Mu</span>
                <span class="sidebar-normal">Manage Users</span>
              </a>
            </li>
            <li>
              <a href="#/pages/user">
                <span class="sidebar-mini-icon">Ep</span>
                <span class="sidebar-normal">Edit Profile</span>
              </a>
            </li>


            <!-- OG template


            <li>
              <a href="#">
                <span class="sidebar-mini-icon">Mp</span>
                <span class="sidebar-normal">My Profile</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">Ep</span>
                <span class="sidebar-normal">Edit Profile</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">S</span>
                <span class="sidebar-normal">Settings</span>
              </a>
            </li>


            END -->


          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { store } from 'src/store'

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        store,
        MUNDIAL_PLATFORM,
        isClosed: true
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
