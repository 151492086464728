// store.js
//import { reactive } from 'vue' // 2.6 last vue version before change to reactive
import Vue from 'vue'
import axios from 'axios'

export const store = Vue.observable({
  // [[globals]]
  propsToSearch: [],
  tableColumns: [],
  colWidthDict: false,
  ogPropsToSearch: false,
  ogTableColumns: false,
  userConfirm: false,
  mmData: false,
  reset_pass: false,
  customQuery: false,
  mmLoader: false,
  aggregateSelected: 'total',
  companySelected: 'all',
  siteSelected: 'all',
  productSelected: 'all',
  subProductSelected: 'all',
  productSubtypeSelected: '',
  segmentSelected: '',
  demoSelected: '',
  languageSelected: '',
  dmaSelected: '',
  genderSelected: '',
  ageSelected: '',
  deviceSelected: '',
  keywordsInput: '',
  exportRows: [],
  mundialData: [],
  mmText: 'Last 30 Days',
  mmDateRange: [],
  mmAvailsRange: '7',
  adminRemoveArr: ['ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  mmRemoveArr: ['inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  clientRemoveArr: ['billing_entity','vertical','lang','inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  iso6391: {
  aa: "Afar",
  ab: "Abkhazian",
  af: "Afrikaans",
  ak: "Akan",
  am: "Amharic",
  an: "Aragonese",
  ar: "Arabic",
  as: "Assamese",
  av: "Avaric",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  bs: "Bosnian",
  ca: "Catalan",
  ce: "Chechen",
  ch: "Chamorro",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  cu: "Church Slavic",
  cv: "Chuvash",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  dv: "Divehi",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  ff: "Fulah",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Scottish Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "Croatian",
  ht: "Haitian",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  ig: "Igbo",
  ii: "Sichuan Yi",
  ik: "Inupiaq",
  io: "Ido",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu",
  kj: "Kwanyama",
  kk: "Kazakh",
  kl: "Kalaallisut",
  km: "Khmer",
  kn: "Kannada",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz",
  la: "Latin",
  lb: "Luxembourgish",
  lg: "Ganda",
  li: "Limburgish",
  ln: "Lingala",
  lo: "Lao",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  lv: "Latvian",
  mg: "Malagasy",
  mh: "Marshallese",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mo: "Moldavian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  nb: "Norwegian Bokmål",
  nd: "North Ndebele",
  ne: "Nepali",
  ng: "Ndonga",
  nl: "Dutch",
  nn: "Norwegian Nynorsk",
  no: "Norwegian",
  nr: "South Ndebele",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetian",
  pa: "Panjabi",
  pi: "Pali",
  pl: "Polish",
  ps: "Pashto",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ro: "Romanian",
  ru: "Russian",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sc: "Sardinian",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Swati",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uighur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapük",
  wa: "Walloon",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang",
  zh: "Chinese",
  zu: "Zulu"
  },
  mmMinMaxDate: [],
  customMinMaxDate: [],
  summarizeRows(tableRows, dimsArr, metricSet) {
    const summaryDict = {};
    let summaryArr = [],
    dailyRows = JSON.parse(JSON.stringify(tableRows)),
    dailyLen = dailyRows.length;

    for (let r = 0; r < dailyLen; r++) {

      // Create new tupleKey
      const cleanRow = dailyRows[r];
      delete cleanRow['date']

      let tmpArr = [], tupleArr = [];

      // Parse through dims & create tuples
      for (let d = 0; d < dimsArr.length; d++) {

        if (dimsArr[d] == 'date') { continue; }

        const tmpDim = dimsArr[d],
        tmpVal = cleanRow[tmpDim];

        tupleArr.push(tmpVal)
      }
      let tmpTuple = tupleArr.join('___');

      // Compile summary
      if (!summaryDict.hasOwnProperty( tmpTuple )) {
        summaryDict[tmpTuple] = summaryArr.length;
        summaryArr.push(cleanRow)
      } else {
        const tmpIdx = summaryDict[tmpTuple];
        for (const metric of metricSet.values()) {
          summaryArr[tmpIdx][metric] += cleanRow[metric]
        }
      }
    }
    return summaryArr
  },
  sanitizeMetrics(cleanRows, metricSet, summaryBool) {
    const math_round = Math.round,
    ogRows = JSON.parse(JSON.stringify(cleanRows)),
    colWidthDict = {}, colSet = new Set(Object.keys(cleanRows[0])),
    dimSet = new Set([...colSet].filter(x => !metricSet.has(x))),
    cleanLen = cleanRows.length;

    //console.log({dimSet,metricSet,store:this})

    if (cleanLen) {
      for (let c = 0; c < cleanLen; c++) {
        const tmpRow = cleanRows[c],
        ogRow = ogRows[c], metricArr = [...metricSet],
        metricLen = metricArr.length;


        //console.log({tmpRow,ogRow})


        for (let m = 0; m < metricLen; m++) {
          const metric = metricArr[m];

          switch (metric) {
            case 'adreq':
              tmpRow.adreq = ogRow.adreq ? ogRow.adreq.toLocaleString("en-US") : 0
              break;
            case 'mon_adreq':
              tmpRow.mon_adreq = ogRow.mon_adreq ? ogRow.mon_adreq.toLocaleString("en-US") : 0
              break;
            case 'imp':
              tmpRow.imp = ogRow.imp ? ogRow.imp.toLocaleString("en-US") : 0
              break;
            case 'revenue':
              tmpRow.revenue = ogRow.revenue ? `${new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(ogRow.revenue)}` : '$0.00'
              //tmpRow.revenue = ogRow.revenue ? `$${ogRow.revenue.toFixed(2)}` : '$0.00'
              break;
            case 'ivt_rate':
              if (summaryBool) {
                tmpRow.ivt_rate = ogRow.ivt_adreq ? `${((100 * ogRow.ivt_adreq) / (ogRow.adreq - ogRow.network_adreq)).toFixed(1)}%` : ''
              } else {
                tmpRow.ivt_rate = ogRow.ivt_rate ? `${ogRow.ivt_rate}%` : ''
              }
              break;
            case 'viewability':
              if (summaryBool) {
                tmpRow.viewability = ogRow.pre_view_imp ? `${(100 * ogRow.view_imp / ogRow.pre_view_imp).toFixed(1)}%` : ''
              } else {
                tmpRow.viewability = ogRow.viewability ? `${ogRow.viewability}%` : ''
              }
              break;
            case 'vcr':
              if (summaryBool) {
                tmpRow.vcr = ogRow.video_start ? `${(100 * ogRow.video_complete / ogRow.video_start).toFixed(1)}%` : ''
              } else {
                tmpRow.vcr = ogRow.vcr ? `${ogRow.vcr}%` : ''
              }
              break;
            case 'fill_rate':
              if (summaryBool) {
                tmpRow.fill_rate = ogRow.fill_rate ? `${(100 * ogRow.imp / ogRow.mon_adreq).toFixed(1)}%` : ''
              } else {
                tmpRow.fill_rate = ogRow.fill_rate ? `${ogRow.fill_rate}%` : ''
              }
              break;
            case 'inter':
              tmpRow.inter = ogRow.inter ? 'Yes' : ''
              break;
            case 'last_login':
              tmpRow.last_login = ogRow.last_login ? ogRow.last_login.split('T')[0]  : ''
              break;
            case 'is_active':
              tmpRow.is_active = ogRow.is_active ? 'Active' : ''
              break;
            default:
              continue
          }

          // Populate metric widths
          const tmpLen = Number.isInteger(tmpRow[metric]) ? tmpRow[metric].toString().length : tmpRow[metric].length,
          titleLen = this.colsDict.hasOwnProperty(metric) && this.colsDict[metric].length > 12 ? this.colsDict[metric].length : 12;
          if (!colWidthDict.hasOwnProperty(metric)) colWidthDict[metric] = 0;
          //if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 9 + 60 : tmpLen * 9 + 60;
          if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;

        }

        const dimArr = [...dimSet],
        dimLen = dimArr.length;
        for (let d = 0; d < dimLen; d++) {

          // Populate dim widths
          const dim = dimArr[d], tmpLen = Number.isInteger(tmpRow[dim]) ? tmpRow[dim].toString().length : tmpRow[dim].length,
          titleLen = this.colsDict.hasOwnProperty(dim) && this.colsDict[dim].length > 12 ? this.colsDict[dim].length : 12;
          if (!colWidthDict.hasOwnProperty(dim)) colWidthDict[dim] = 0;
          //if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 8 + 80 : tmpLen * 8 + 80;
          if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;

        }

      }
    }
    this.colWidthDict = colWidthDict

    //console.log({colWidthDict})

    return cleanRows
  },
  //filterMundial([['type', 'wood'], ['size', 8], ['someother', 'value']]);
  filterMundial(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters})
    //console.log({mundial:this.mmData.mundial})

    return (this.customQuery ? this.mmData.custom : this.mmData.mundial).filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate,objCheck})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (self.customQuery && tmpDate == sDate) {
            bool = true;
          }
          if (self.mmData.sessionFilters && self.customQuery && tmpDate == sDate - 1) {
            bool = true;
          }
          /*
          if (self.mmData.sessionFilters && self.mmData.adjust_custom && tmpDate < eDate - 2) {
            bool = true;
          }
          if (self.customSession && tmpDate > eDate - 1) {
            bool = false;
          }
          */
          if (objCheck || self.customQuery) {
            self.mmText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  // UPDATE: need to include changes from filterMundial
  filterCharts(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters})
    //console.log({mundial:this.mmData.mundial})

    return this.mmData.mundial_charts.filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (objCheck) {
            self.mmText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  reformatDate(oldDate) {
    return parseInt([oldDate.split('-')[2],oldDate.split('-')[0],oldDate.split('-')[1]].join(''))
  },
  mmDate() {
        Date.prototype.GetFirstDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay()+ (this.getDay() == 0 ? -6 : 1) )));
        }
        Date.prototype.GetLastDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + 7)));
        }
        Date.prototype.GetLastSeven = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastThirty = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 30);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetThisWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            return ({startDay, endDay});
        }
        Date.prototype.GetThisMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this.getFullYear(), this.getMonth() + 1, 0);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            endDay.setTime(endDay.getTime() - 3600 * 1000 * 24 * 7);
            return ({startDay, endDay});
        }
        Date.prototype.GetLastMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth() - 1, 1),
              endDay = new Date(this.getFullYear(), this.getMonth(), 0);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            endDay.setTime(endDay.getTime() + 3600 * 1000 * 24);
            return ({startDay, endDay});
        }
        Date.prototype.GetMonthToDate = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }

        const adjDate = new Date();
        adjDate.setTime(adjDate.getTime() - 3600 * 1000 * 8)

        return adjDate
  },
  clearMundialTimeout() {
        clearTimeout(this.session_timeout)
  },
  mmTimeout() {
        const minutes = 90;
        //const minutes = 0.5;
        this.session_timeout = setTimeout(() => { this.confirmSession() }, 1000 * 60 * minutes)
  },
  async logoutMundial() {
        const LogOut = await axios.get('logout/'),
        boolCheck = typeof LogOut.data.logout !== "undefined" ? LogOut.data.logout : 'expired';

        //console.log({boolCheck, store:this, session_router:this.session_router})

        // MUST match [[globals]]
        if (boolCheck) {
            this.propsToSearch = []
            this.tableColumns = []
            this.colWidthDict = false
            this.ogPropsToSearch = false
            this.ogTableColumns = false
            this.userConfirm = false
            this.mmData = false
            this.reset_pass = false
            this.customQuery = false
            this.mmLoader = false
            this.aggregateSelected = 'total'
            this.companySelected = 'all'
            this.siteSelected = 'all'
            this.productSelected = 'all'
            this.subProductSelected = 'all'
            this.productSubtypeSelected = ''
            this.segmentSelected = ''
            this.demoSelected = ''
            this.languageSelected = ''
            this.dmaSelected = ''
            this.genderSelected = ''
            this.ageSelected = ''
            this.deviceSelected = ''
            this.keywordsInput = ''
            this.exportRows = []
            this.mundialData = []
            this.mmText = 'Last 30 Days'
            this.mmDateRange = []
            this.mmMinMaxDate = []
            this.customMinMaxDate = []
            this.mmAvailsRange = '7'
        }

        // Redirect Login (expired sessions)
        if(boolCheck === 'expired'){
            this.session_router.push({'name':'Login'})
            this.session_router = false
            this.clearMundialTimeout()
        }
  },
  async confirmTC(tcForm) {
        // POST Confirm Terms of Use
        const UpdateData = await axios.post('confirmterms/',tcForm),
        boolCheck = UpdateData.data.user;

        if (boolCheck) {
          this.mmData.user = boolCheck
        }
  },
  async valid_reset_pass() {
        // POST Confirm Terms of Use
        const email = this.reset_pass ? this.reset_pass.email : false,
        password = this.reset_pass ? this.reset_pass.password : false,
        ValidResetPass = email && password ? await axios.post('validreset/',{ email, password }) : false,
        boolCheck = ValidResetPass ? ValidResetPass.data.valid_reset : ValidResetPass;

        //console.log({ValidResetPass, boolCheck})

        return boolCheck
  },
  async resetSessionFilters(sessionForm) {
        // POST add filters to Django session
        const UserSession = await axios.post('sessionfilters/',sessionForm), InvalidSess = UserSession.data.invalid_session;

        // Alert session errors
        if (InvalidSess) {
          alert('Session invalid! Please notify Mundial Media admins.')
        }
  },
  async resetMundialPass(resetPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({resetPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('resetpass/',resetPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.mmData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (InvalidPass) {
          alert(`Please check your email inbox for ${resetPassForm.email}!`)
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async setNewPass(setPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({setPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('setnewpass/',setPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.mmData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (!InvalidPass) {
          this.reset_pass = false
          alert('Password set successfully!')
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async confirmSession() {
        // GET Confirm Session
        const SessionData = await axios.get('confirmsession/'),
        boolCheck = typeof SessionData.data.status !== 'undefined' ? SessionData.data.status : false;

        //console.log({boolCheck})

        // Logout Mundial (if expired)
        if (!boolCheck) {
          this.logoutMundial()
        } else {
          this.clearMundialTimeout()
          this.mmTimeout()
        }
  },
  async loginMundial(loginForm) {
        if (loginForm.password) this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const UserData = await axios.post('login/',loginForm)

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        this.mmData = UserData.data.user ? UserData.data : false
        if (this.mmData) this.mmTimeout()

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async initCLD() {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initcadmus/'),
        boolCheck = MundialData.data.mundial;

        // Update State

        console.log('_ _ _ _ _ _')
        console.log({MundialData})
        console.log('_ _ _ _ _ _')

        if (boolCheck) {
          const dataLen = boolCheck.length;

          for(let d = 0; d < dataLen; d++) {
            boolCheck[d].page_json = JSON.parse(boolCheck[d].page_json)
            boolCheck[d].image_json = JSON.parse(boolCheck[d].image_json)
            boolCheck[d].results_json = JSON.parse(boolCheck[d].results_json)
          }

          this.mmData.cadmus = boolCheck
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async initMundial() {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initdata/'),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (boolCheck) this.mmData.mundial = boolCheck

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async createMundial(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const ProfileData = await axios.post('createmundial/',mForm),
        boolCheck = ProfileData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async updateMundial(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdate/',uForm),
        boolCheck = UpdateData.data.user;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.user = boolCheck
            this.mmData.users_list = UpdateData.data.users_list

            return true
        } else {
            return false
        }
  },
async updatePlatformUser(uForm) {
        //console.log('_____')
        //console.log('')
        //console.log({uForm})
        //console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('updateuser/',uForm),
        boolCheck = UpdateData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.users_list = boolCheck
            return true
        } else {
            return false
        }
  },
  async deletePlatformUser(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deleteuser/',mForm),
        boolCheck = MundialData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async mmAvails(aForm) {
        //console.log('_____')
	//console.log('')
        //console.log({aForm})
	//console.log('')
        //console.log('_____')

        // POST AvailsData
        const AvailsData = await axios.post('mundialavails/',aForm),
        boolCheck = AvailsData.data.avails;

        // Return avails
        //console.log('_ _ _ _ _ _')
        //console.log({AvailsData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        return boolCheck
  },
  async customMundial() {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const sdate = this.customMinMaxDate[0].toISOString().split('T')[0], edate = this.customMinMaxDate[1].toISOString().split('T')[0],
        date_range = [sdate, edate],
        customData = await axios.post('customdata/',{ date_range }),
        boolCheck = customData.data.custom;

        // Return Data

        //console.log('_ _ _ _ _ _')
        //console.log({customData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            return boolCheck
        } else {
            return this.mmData.mundial
        }
  },
  async sessionLogin(session_router) {
        await this.loginMundial({email:'sessionid', password:false})

        //console.log({store, session:true})

        // Redirect
        if(store.mmData){
          session_router.push({'name':'Overview'})
        }
  },
  download(data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    const ahoraMismo = new Date();
    a.setAttribute('download', `mundial_report_${this.mmText.toLowerCase().replace(' ','_')}__${ahoraMismo.toISOString()}.csv`);
    // Performing a download with click
    a.click()
  },
  csvmaker(exportArr) {
    // Empty array for storing the values
    let csvRows = [], headers = Object.keys(exportArr[0]),
    dataArr = JSON.parse(JSON.stringify(exportArr)),
    dataLen = dataArr.length;
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const adminRemoveArr = this.adminRemoveArr, adminRemoveSet = new Set(adminRemoveArr),
    clientRemoveArr = this.clientRemoveArr, clientRemoveSet = new Set(clientRemoveArr),
    mmRemoveArr = this.mmRemoveArr, mmRemoveSet = new Set(mmRemoveArr),
    is_staff = this.mmData.user.is_staff,
    is_admin = this.mmData.user.is_superuser;
    if(is_staff && !is_admin) {
       headers = headers.filter(function (item) {
          return !mmRemoveSet.has(item);
       });
    } else if (!is_admin) {
       headers = headers.filter(function (item) {
          return !clientRemoveSet.has(item);
       });
    } else {
       headers = headers.filter(function (item) {
          return !adminRemoveSet.has(item);
       });
    }
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    const cleanHeaders = [],
    colsDict = this.colsDict,
    headLen = headers.length;
    for (let h = 0; h < headLen; h++) {
      const cleanHead = colsDict[headers[h]];
      cleanHeaders.push(cleanHead)
    }
    csvRows.push(cleanHeaders.join(','));
    // Pushing Object values into array
    // with comma separation
    for(let d = 0; d < dataLen; d++) {
       let dataRow = dataArr[d];
       if(is_staff && !is_admin) {
          mmRemoveArr.forEach(e => delete dataRow[e])
       } else if (!is_admin) {
          clientRemoveArr.forEach(e => delete dataRow[e])
       } else {
          adminRemoveArr.forEach(e => delete dataRow[e])
       }

       // TEST (maintained for testing purposes)
       //console.log({dataRow})

       // Add quotes to preserve commas during CSV export
       for(let h = 0; h < headLen; h++) {
         const tmpHeader = headers[h];
         dataRow[tmpHeader] = `\"${dataRow[tmpHeader]}\"`
       }

       //console.log({dataRow})

       const val = Object.values(dataRow).join(',');
       csvRows.push(val)
    }
    // Returning the array joining with new line
    return csvRows.join('\n')
  },
  queriedData(searchQuery) {
        if (!searchQuery.length) {
          //return this.mmData.mundial
          //return this.mundialData
          return this.exportRows
        }
        const propsToSearch = this.propsToSearch,
        lowerSearchQuery = searchQuery.toLowerCase(),
        propLen = propsToSearch.length;
        //let result = this.mmData.mundial
        //let result = this.mundialData
        let result = this.exportRows
          .filter((row) => {
            let isIncluded = false


            // TEMP
            //console.log({row})



            for(let k = 0; k < propLen; k++) {
              const tmpKey = propsToSearch[k];

              // TEST (maintained for testing purposes)
              //console.log({key})
              //console.log(row[key])
              //console.log(row[key].toLowerCase())

              let rowValue = row[tmpKey].toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        return result
  },
  async exportMundial(searchQuery) {
        this.mmLoader = true

        const csvData = this.queriedData(searchQuery), csvFile = this.csvmaker(csvData);
        await this.download(csvFile);

        this.mmLoader = false
  },
})
